/* src/fonts.css */

@font-face {
  font-family: "Roboto";
  src: url("./Roboto-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "SUSE";
  src: url("./SUSE-VariableFont_wght.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "LilitaOne";
  src: url("./LilitaOne-Regular.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
