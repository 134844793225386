* {
  transition: all ease-in 0.3s;
  background-repeat: no-repeat;
}
body {
  background-color: #01020e;
  color: #ccc;
  max-width: 1524px;
  margin: 0 auto;
  scroll-behavior: smooth;
}
a {
  text-decoration: none;
}
img {
  display: inline-block;
}
